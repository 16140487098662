import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { SSOsService } from "../ssos.service";
import { SsoConfig } from "../../../../models/shared";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { TitleCasePipe } from "@angular/common";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { map } from "rxjs/operators";

@Component({
    selector: "app-sso-form",
    templateUrl: "./sso-form.component.html",
    providers: [TitleCasePipe]
})
export class SSOFormComponent implements OnInit {
    sso = new SsoConfig();
    private ssoId: string;
    private action: string;
    configTypeControl = new FormControl<"openid_disc" | "manual">("openid_disc");
    configTypeTypes = {
        MANUAL: "manual",
        OPENID_DISCOVERY: "openid_disc"
    };

    submitted = false;
    isEdit = false;
    loading = true;
    saving = false;
    isInvalidSubmit = false;

    form = this.formBuilder.group({
        name: ["", [Validators.required, Validators.minLength(2), Validators.pattern(Constants.validators.name)]],
        authorizationURL: ["", [Validators.required, Validators.minLength(2)]],
        openIdConfigURL: ["", [Validators.required, Validators.minLength(2)]],
        tokenURL: ["", [Validators.required, Validators.minLength(2)]],
        clientID: ["", [Validators.required, Validators.minLength(2)]],
        clientSecret: ["", [Validators.required, Validators.minLength(2)]],
        userInfoURL: ["", [Validators.minLength(2)]],
        sso_only_preapproved: [false]
    });

    ssoNames$ = this.sks.ssos.pipe(
        map(ssos => {
            if (this.ssoId) ssos = ssos.filter(t => t.id !== this.ssoId);
            return ssos.map(t => t.name);
        })
    );

    constants = Constants;
    window = window;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private sks: SSOsService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe,
        private formBuilder: FormBuilder
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.ssoId = params.get("id");
            this.action = params.get("action");
            if (this.ssoId) {
                this.isEdit = true;
                this.sks.refreshSSOs();

                this.sks.ssos.pipe(take(1)).subscribe(ssos => {
                    this.sso = Object.assign(
                        {},
                        ssos.find(t => t.id === this.ssoId)
                    );
                    this.prepForm();
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        });
    }

    private prepForm() {
        // Set Title
        this.titleService.setTitle(
            this.translate.instant("SSO") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.sso && this.sso.name ? this.sso.name : "")
        );

        if (this.isEdit) {
            this.configTypeControl.setValue(this.sso.openIdConfigURL ? "openid_disc" : "manual");

            this.form.patchValue({
                name: this.sso.name,
                openIdConfigURL: this.sso.openIdConfigURL,
                userInfoURL: this.sso.userInfoURL,
                sso_only_preapproved: !!this.sso.sso_only_preapproved
            });

            this.updateValidators();
        }
    }

    ngOnInit() {
        this.sks.refreshSSOs();

        this.configTypeControl.valueChanges.subscribe(() => this.updateValidators());

        this.prepForm();

        this.updateValidators();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            ...this.form.value,
            authorizationURL: this.valueOrEmpty(this.form.value.authorizationURL),
            tokenURL: this.valueOrEmpty(this.form.value.tokenURL),
            clientID: this.valueOrEmpty(this.form.value.clientID),
            clientSecret: this.valueOrEmpty(this.form.value.clientSecret)
        };
        if (this.configTypeControl.value === "openid_disc") {
            model.authorizationURL = undefined;
            model.tokenURL = undefined;
            model.userInfoURL = undefined;
        } else {
            model.openIdConfigURL = undefined;
        }
        const result = this.isEdit ? await this.sks.updateSSO(this.sso, model) : await this.sks.addSSO(model);
        this.saving = false;
        if (result) await this.router.navigate([Constants.urls.accountManagement.sso]);
        this.isInvalidSubmit = true;
    }

    private valueOrEmpty(value?: string) {
        return !this.isEdit || value ? value : undefined;
    }

    private updateValidators() {
        this.form.controls.openIdConfigURL.clearValidators();
        this.form.controls.authorizationURL.clearValidators();
        this.form.controls.tokenURL.clearValidators();

        if (this.configTypeControl.value === "openid_disc") {
            this.form.controls.openIdConfigURL.setValidators([Validators.required, Validators.minLength(2)]);
        } else {
            if (!this.isEdit) {
                this.form.controls.authorizationURL.setValidators([Validators.required, Validators.minLength(2)]);
                this.form.controls.tokenURL.setValidators([Validators.required, Validators.minLength(2)]);
            }
        }

        this.form.controls.openIdConfigURL.updateValueAndValidity();
        this.form.controls.authorizationURL.updateValueAndValidity();
        this.form.controls.tokenURL.updateValueAndValidity();
    }
}
